<template>
  <app-autocomplete :items="items" label="Licence Category" v-on:input="$emit('input', $event)" v-bind:value="value" :disabled="disabled" :rules="rules"/>
</template>

<script>
import AppAutocomplete from "@/components/app/Autocomplete";
export default {
  name: "LicenceCategoryPicker",
  components: {AppAutocomplete},
  props: ['value','disabled'],
  data() {
    return {
      items:[
        {text:"Aeroplanes", value:"A"},
        {text:"Helicopters", value:"H"},
        {text:"Gyroplanes", value:"G"},
        {text:"Balloon", value:"B"},
        {text:"Airship", value:"As"}
      ],
      rules: [
        v => !!v || 'selection is required',
      ],
    }
  }
}
</script>

<style scoped>

</style>
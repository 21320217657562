<template>
  <v-form
      ref="form"
      v-model="valid"
      v-if="!disabled"
  >
    <v-container>
      <v-row>
        <v-col cols="12" xs="12" sm="5" md="4">
          <div class="caption">
            {{ title }}
          </div>
          <div>
            <licence-ratings-certificates-and-privileges-picker v-model="id" :items="items" @input="checkItemExpires"/>
          </div>
        </v-col>
        <v-col cols="12" xs="12" sm="5" md="3">
          <div class="caption px-2">Remarks and Restrictions</div>
          <div>
            <app-text-field v-model="remarksAndRestrictions"/>
          </div>
        </v-col>
        <v-col cols="12" xs="12" sm="5" md="4">
          <div class="caption px-2">Valid until</div>
          <div v-if="expires && id && licenceType!=='LAPL'">
            <app-date-picker :label="`${title } valid until`" :min="minEndorsementDate"
                             v-model="validUntil" :rules="rules"/>
          </div>
          <div v-else>Non -expiring</div>
        </v-col>
        <v-spacer/>
        <v-col cols="12" xs="12" sm="2" md="1">
          <div class="caption mx-3">Action</div>
          <div class="mx-3">
            <v-btn x-small fab color="primary" class="mr-4" @click="add()"
                   :disabled="!formComplete || !valid">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import LicenceRatingsCertificatesAndPrivilegesPicker
  from "@/components/licence/LicenceRatingsCertificatesAndPrivilegesPicker";
import AppTextField from "@/components/app/TextField";
import {mapState} from "vuex";
import AppDatePicker from "@/components/app/DatePicker";
import {format, sub} from "date-fns";

export default {
  name: "LicenceRatingsCertificatesAndPrivilegesManagerNewRow",
  components: {AppDatePicker, AppTextField, LicenceRatingsCertificatesAndPrivilegesPicker},
  props: {
    title: {
      type: String
    },
    items: {
      type: Array
    },
    disabled: {
      type: Boolean
    },
    licenceType: {
      type:String
    }
  },
  computed: {
    ...mapState("licence", ["licenceEndorsementTypes"]),
    formComplete() {
      return true;
    },
    rollingExpiryItems() {
      return this.licenceEndorsementTypes.filter(l => l.expires).map(l => l._id)
    },
    minEndorsementDate() {
      return format(sub(new Date(),{years:6}),'yyyy-MM-dd')
    }

  },
  methods: {
    checkItemExpires() {
      if (!this.id) return
      const item = this.licenceEndorsementTypes.find(e => e._id === this.id)
      this.expires =  item.expires
    },

    validate() {
      this.$refs.form.validate()
    },
    add() {
      if (!this.$refs.form.validate()) return
      this.$emit("add", {
        id: this.id,
        name: this.name,
        expires: this.expires,
        remarksAndRestrictions: this.remarksAndRestrictions,
        validUntil: new Date(this.validUntil)
      })
      this.id = null
      this.name = null
      this.validUntil = null
      this.remarksAndRestrictions = 'No Remark'
      this.expires = false
    }
  },
  watch: {
    id(newVal) {
      if (newVal) {
        this.name = newVal
        this.showValidUntilDatePicker = true;
        this.validUntil = new Date().toISOString().substring(0, 10)
      }
    },
  },
  data() {
    return {
      valid: false,
      showValidUntilDatePicker: false,
      id: null,
      name: null,
      expires: false,
      validUntil: null,
      remarksAndRestrictions: 'No Remark',
      rules: [
        v => !!v || 'selection is required',
      ],
    }
  }
}
</script>

<style scoped>

</style>
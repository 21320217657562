<template>
  <app-autocomplete :items="items" label="Licence Type" v-on:input="$emit('input', $event)" v-bind:value="value" :disabled="disabled" :rules="rules" />
</template>

<script>
import AppAutocomplete from "@/components/app/Autocomplete";
export default {
  name: "LicenceTypePicker",
  components: {AppAutocomplete},
  props: ['value','disabled'],
  data() {
    return {
      items:[
        {text:"National Private Pilot Licence (NPPL)", value:"NPPL"},
        {text:"Light aircraft pilot licence", value:"LAPL"},
        {text:"Private pilot licence", value:"PPL"},
        {text:"Commercial pilot licence", value:"CPL"},
        {text:"Airline transport pilot licence", value:"ATPL"}
      ],
      rules: [
        v => !!v || 'selection is required',
      ],
    }
  },
  computed:{

  }
}
</script>

<style scoped>

</style>
<template>
  <app-autocomplete :items="items" item-text="name" item-value="_id" v-on:input="$emit('input', $event)" v-bind:value="value" :rules="rules"/>
</template>

<script>
import AppAutocomplete from "@/components/app/Autocomplete";

export default {
  name: "LicenceRatingsCertificatesAndPrivilegesPicker",
  components: {AppAutocomplete},
  props: {
    value: {},
    items:{
      type: Array,
      default:()=>[]
    }
  },
  data() {
    return {
      rules: [
        v => !!v || 'Selection is required',
      ],
    }
  }
}
</script>

<style scoped>

</style>
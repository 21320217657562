<template>
  <v-sheet>
    <div class="text-h6">{{ $route.name }}</div>
    <div class="text-caption">Licence Information</div>
    <v-form
        ref="form"
        v-model="valid"
    >
    <v-container v-if="activeLicence">
      <v-row no-gutters>
        <v-col cols="2" xs="2" sm="1" md="1" lg="1">I</v-col>
        <v-col cols="10" xs="10" sm="10" md="11" lg="11">
          <country-picker v-model="activeLicence.licenceCountryCode" title="Licence State of Issue" returntype="iso3" :disabled="!isEditable" @input="validate"/>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" xs="2" sm="1" md="1" lg="1">II</v-col>
        <v-col cols="12" xs="6" sm="7" md="3">
          <licence-type-picker v-model="activeLicence.licenceType" label="licence Title" :disabled="!isEditable"  @input="validate"/>
        </v-col>
        <v-spacer/>
        <v-col cols="12" xs="4" sm="4" md="2">
          <licence-category-picker v-model="activeLicence.licenceCategory" label="licence Category" :disabled="!isEditable" @input="validate"/>
        </v-col>
        <v-spacer/>
        <v-col cols="12" xs="6" sm="4" md="4">
          <app-date-picker v-model="activeLicence.licenceDateOfInitialIssue" label="Licence Date of Initial Issue"
                           :max="new Date().toISOString().substring(0,10)" :disabled="!isEditable" @input="validate"/>
        </v-col>
        <v-col cols="12" xs="6" sm="4" md="1">
          <div class="text-caption">Country Code</div>
          <div class="text-body-1">{{ !activeLicence.licenceCountryCode ? '' : activeLicence.licenceCountryCode }}</div>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="2" xs="2" sm="1" md="1" lg="1">III</v-col>
        <v-col cols="10" xs="10" sm="10" md="11" lg="11">
          <app-text-field v-model="activeLicence.licenceSerialNumber" label="Licence Serial Number" :disabled="!isEditable" @input="validate" :rules="rules"/>
        </v-col>
      </v-row>
      <v-row no-gutters v-show="false">
        <v-col cols="2" xs="2" sm="1" md="1" lg="1">IV</v-col>
        <v-col cols="10" xs="10" sm="10" md="11" lg="11">
          <app-autocomplete :items="persons" item-text="name" item-value="_id" v-model="activeLicence.linkedPersonId"
                            label="First name and Last name of holder" disabled/>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="2" xs="2" sm="1" md="1" lg="1">XII</v-col>
        <v-col cols="10" xs="10" sm="11" md="11" lg="11">
          <div class="text-caption">Ratings Certificates and privileges</div>
          <licence-ratings-certificates-and-privileges-manager title="Class/Type/IR"
                                                               :items="activeLicence.licenceRatings"
                                                               :new-item-picker-list="availableRatingList"
                                                               :licence-type="activeLicence.licenceType"
                                                               v-on:changeItems="updateLicenceRating($event)" :disabled="!isEditable"  @input="validate"/>
          <v-divider class="my-2 surface"/>
          <licence-ratings-certificates-and-privileges-manager title="Instructors/Examiners"
                                                               :items="activeLicence.licenceCertificates"
                                                               :new-item-picker-list="availableCertificateList"
                                                               :licence-type="activeLicence.licenceType"
                                                               v-on:changeItems="updateLicenceCertificates($event)" :disabled="!isEditable"  @input="validate"/>
        </v-col>
      </v-row>
    </v-container>
    </v-form>
    <v-toolbar dense flat>
      <v-btn color="primary" @click="save" v-if="isEditable" :disabled="!valid">
        <v-icon left>mdi-home</v-icon>
        Save
      </v-btn>
      <v-btn color="secondary" @click="closePage">{{isEditable ? 'Cancel' : 'Return'}}</v-btn>
    </v-toolbar>
  </v-sheet>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {LicenceHttp} from "@/http/LicenceHttp";
import LicenceRatingsCertificatesAndPrivilegesManager
  from "@/components/licence/LicenceRatingsCertificatesAndPrivilegesManager";
import CountryPicker from "@/components/shared/CountryPicker";
import LicenceTypePicker from "@/components/licence/LicenceTypePicker";
import LicenceCategoryPicker from "@/components/licence/LicenceCategoryPicker";
import AppDatePicker from "@/components/app/DatePicker";
import AppTextField from "@/components/app/TextField";
import AppAutocomplete from "@/components/app/Autocomplete";
import {isAuthorised} from "@/util/helpers";

export default {
  name: "LicencePage",
  components: {
    AppAutocomplete,
    AppTextField,
    AppDatePicker,
    LicenceCategoryPicker, LicenceTypePicker, CountryPicker, LicenceRatingsCertificatesAndPrivilegesManager
  },
  computed: {
    ...mapState("licence", ["activeLicence", "licenceEndorsementTypes"]),
    ...mapState("country", ["countryList"]),
    ...mapState("person", ["persons", "activePerson", "loggedInPerson"]),
    ...mapState("user", ["accountRoles"]),
    ...mapState("app", ["loggedInUser"]),
    isEditable() {
      return this.activePerson._id === this.loggedInPerson._id || isAuthorised(this.accountRoles, ["rootAdmin", "admin"])
    },
    endorsedRatings() {
      return this.activeLicence._id ? this.activeLicence.licenceRatings.map(r => r.id) : []
    },
    endorsedCertificates() {
      return this.activeLicence._id ? this.activeLicence.licenceCertificates.map(r => r.id) : []
    },
    availableRatingList() {
      return this.licenceEndorsementTypes.filter(endorsement => endorsement.type === 'rating').filter(endorsement => !this.endorsedRatings.includes(endorsement._id))
    },
    availableCertificateList() {
      return this.licenceEndorsementTypes.filter(endorsement => endorsement.type === 'certificate').filter(endorsement => !this.endorsedCertificates.includes(endorsement._id))
    },
    formComplete() {
      return this.step1Complete
    },
  },
  methods: {
    ...mapActions("user", ["setFlyingStatus"]),
    ...mapActions("licence", ["setActiveLicence"]),
    validate () {
      this.$refs.form.validate()
    },
    closePage() {
      this.$router.push({name: 'Licences'})
    },
    async createItem(item) {
      await LicenceHttp.createNewLicence(item)
      await this.setActiveLicence({})
      await this.closePage()
    },
    async updateItem(id, item) {
      await LicenceHttp.updateLicence(id, item)
      await this.setActiveLicence({})
      await this.closePage()
    },
    updateLicenceRating(ratings) {
      this.activeLicence.licenceRatings = ratings
    },
    updateLicenceCertificates(certificates) {
      this.activeLicence.licenceCertificates = certificates
    },
    async save() {
      if (!this.$refs.form.validate()) return
      this.activeLicence.licenceTitle = `${this.activeLicence.licenceType} (${this.activeLicence.licenceCategory})`
      const stateOfIssue = this.countryList.find(country => country.iso3 === this.activeLicence.licenceCountryCode)
      stateOfIssue ? this.activeLicence.stateOfIssueName = stateOfIssue.full_name : ''
      if (this.activeLicence._id) {
        const id = this.activeLicence._id
        delete this.activeLicence._id
        await this.updateItem(id, this.activeLicence)
        await this.setFlyingStatus(this.loggedInUser._id)
      }else {
        await this.createItem(this.activeLicence)
      }
    },
  },
  data() {
    return {
      valid: false,
      defaultItem: {
        linkedPersonId: null,
        stateOfIssueName: null,
        licenceType: null,
        licenceTitle: null,
        licenceCategory: null,
        licenceDateOfInitialIssue: null,
        licenceCountryCode: '',
        licenceSerialNumber: null,
        licenceRatings: [],
        licenceCertificates: [],
        licenceIsSuspended: false
      },
      rules: [
        v => !!v || 'selection is required',
      ],
    }
  },
  mounted() {
    this.validate()
    if (!this.activeLicence) this.setActiveLicence(this.defaultItem)
  }
}
</script>

<style scoped>

</style>
<template>
  <v-data-table
      :headers="headers"
      :items="items"
      no-results-text="No Entries."
      no-data-text="No Entries."
      :items-per-page="-1"
      hide-default-footer
  >
    <template v-slot:item.validUntil="{item }">
      {{ licenceType==='LAPL' ? '': item.expires  ? formatDate(item.validUntil,'dd MMM yyyy') : 'Non-expiring' }}
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
          small
          @click="removeItem(item)"
          v-if="!disabled"
      >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:footer>
      <v-divider class="mt-2 mb-4"/>
      <licence-ratings-certificates-and-privileges-manager-new-row :title="title" v-on:add="addItem($event)" :items="newItemPickerList" :disabled="disabled" :licence-type="licenceType" />
    </template>
  </v-data-table>
</template>

<script>
import LicenceRatingsCertificatesAndPrivilegesManagerNewRow
  from "@/components/licence/LicenceRatingsCertificatesAndPrivilegesManagerNewRow";
import {format} from "date-fns";

export default {
  name: "LicenceRatingsCertificatesAndPrivilegesManager",
  components: {
    LicenceRatingsCertificatesAndPrivilegesManagerNewRow
    },
  props: {
    title: {
      type: String
    },
    newItemPickerList: {
      type: Array
    },
    items: {
      type: Array
    },
    disabled:{
      type: Boolean
    },
    licenceType: {
      type: String,
    }
  },
  data() {
    return {
      editedIndex:null,
      editedItem: null,
      localItems: [],
      headers: [
        {
          text: 'Type',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {text: 'Remarks and Restrictions', value: 'remarksAndRestrictions'},
        {text: 'Valid until', value: 'validUntil'},
        {text: 'Actions', value: 'actions'}
      ],
    }
  },
  watch: {
    items(newVal) {
      newVal ? this.setLocalItems():''
    },
  },
  methods: {
    formatDate(item,formatString){
      return format(new Date(item),formatString.toString())
    },
    setLocalItems(){
      this.localItems = this.items
    },
    clearTempVars(){
      this.editedIndex = null
      this.editedItem = null
    },
    addItem(item) {
      this.setLocalItems()
      this.editedItem= item
      let endorsement = this.newItemPickerList.find( i=> i._id === this.editedItem.id)
      this.editedItem.name = endorsement.name
      this.localItems.push(this.editedItem)
      this.$emit('changeItems', this.localItems)
      this.clearTempVars()
    },
    edit(item) {
      console.log(item)
    },
    removeItem(item) {
      this.setLocalItems()
      this.editedIndex = this.localItems.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.localItems.splice(this.editedIndex, 1)
      this.$emit('changeItems', this.localItems)
      this.clearTempVars()
    }
  },
}
</script>

<style scoped>

</style>
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.disabled)?_c(VForm,{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","xs":"12","sm":"5","md":"4"}},[_c('div',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',[_c('licence-ratings-certificates-and-privileges-picker',{attrs:{"items":_vm.items},on:{"input":_vm.checkItemExpires},model:{value:(_vm.id),callback:function ($$v) {_vm.id=$$v},expression:"id"}})],1)]),_c(VCol,{attrs:{"cols":"12","xs":"12","sm":"5","md":"3"}},[_c('div',{staticClass:"caption px-2"},[_vm._v("Remarks and Restrictions")]),_c('div',[_c('app-text-field',{model:{value:(_vm.remarksAndRestrictions),callback:function ($$v) {_vm.remarksAndRestrictions=$$v},expression:"remarksAndRestrictions"}})],1)]),_c(VCol,{attrs:{"cols":"12","xs":"12","sm":"5","md":"4"}},[_c('div',{staticClass:"caption px-2"},[_vm._v("Valid until")]),(_vm.expires && _vm.id && _vm.licenceType!=='LAPL')?_c('div',[_c('app-date-picker',{attrs:{"label":`${_vm.title } valid until`,"min":_vm.minEndorsementDate,"rules":_vm.rules},model:{value:(_vm.validUntil),callback:function ($$v) {_vm.validUntil=$$v},expression:"validUntil"}})],1):_c('div',[_vm._v("Non -expiring")])]),_c(VSpacer),_c(VCol,{attrs:{"cols":"12","xs":"12","sm":"2","md":"1"}},[_c('div',{staticClass:"caption mx-3"},[_vm._v("Action")]),_c('div',{staticClass:"mx-3"},[_c(VBtn,{staticClass:"mr-4",attrs:{"x-small":"","fab":"","color":"primary","disabled":!_vm.formComplete || !_vm.valid},on:{"click":function($event){return _vm.add()}}},[_c(VIcon,[_vm._v("mdi-plus")])],1)],1)])],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
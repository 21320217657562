<template>
  <app-autocomplete :items="countryList" item-text="full_name" :item-value="returntype.toString()" :label="title"
                    v-on:input="$emit('input', $event)" v-bind:value="value" :disabled="disabled" :rules="rules"/>
</template>

<script>
import AppAutocomplete from "@/components/app/Autocomplete";
import {mapState} from "vuex";

export default {
  name: "CountryPicker",
  components: {AppAutocomplete},
  props: ["value","title","returntype",'disabled'],
  computed: {
    ...mapState("country", ["countryList"])
  },
  data() {
    return {
      rules: [
        v => !!v || 'County Selection is required',
      ],
    }
  }
}
</script>

<style scoped>

</style>